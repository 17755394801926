<template>
  <div class="login">
    <a-card class="box-card">
      <h1 v-if="inviteInfo">
        <span
          >用户
          {{ inviteInfo.user ? this.inviteInfo.user.username : null }}</span
        >
        <br />
        <span
          >邀请你加入「{{
            inviteInfo.team ? this.inviteInfo.team.name : null
          }}」</span
        >
      </h1>

      <a-button
        v-if="this.userAuth === true"
        class="margin-top"
        type="primary"
        size="large"
        @click="joinTeam"
        >加入团队</a-button
      >

      <a-form
        v-if="this.userAuth === false"
        class="login-form"
        :form="form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-select
            :disabled="true"
            v-decorator="[
              'userRole',
              {
                initialValue: inviteParams
                  ? inviteParams.memberRole
                  : undefined,
              },
            ]"
          >
            <a-select-option
              v-for="role in teamMemberRoleOptions"
              :key="role.value"
              :disabled="role.value === 'owner'"
            >
              用户角色: {{ role.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-input
            allowClear
            placeholder="邮箱地址"
            @change="changeEmail"
            v-decorator="[
              'email',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入邮箱地址',
                  },
                  {
                    type: 'email',
                    message: '邮箱格式有误',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="mail" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            allowClear
            placeholder="邮箱验证码"
            v-decorator="[
              'emailcode',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入验证码',
                  },
                  {
                    len: 6,
                    message: '验证码格式有误',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="code" style="color:rgba(0,0,0,.25)" />
            <a-button
              slot="suffix"
              type="link"
              class="emailcode-btn"
              :disabled="btnStyle !== 'actived'"
              @click="sendEmailCode"
              >{{ btnText }}</a-button
            >
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            allowClear
            placeholder="用户名"
            v-decorator="[
              'username',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入用户名',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-input
            allowClear
            placeholder="密码"
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ],
              },
            ]"
          >
            <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
          </a-input>
        </a-form-item>

        <a-form-item style="margin: 0">
          <a-button type="primary" html-type="submit" size="large" @click="handleSubmit">
            注册
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import { ENV, Storage, Encrypt, getUrlParams } from '@/utils';
import { TEAM_MEMBER_ROLE_OPTIONS } from '@/utils/constant';
export default {
  name: 'join',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'registerForm' }),
      teamMemberRoleOptions: TEAM_MEMBER_ROLE_OPTIONS,
      timer: null,
      ajaxFlag: true,
      email: null,
      btnText: '发送验证码',
      btnStyle: 'actived',
      num: 60,
      inviteParams: null,
      inviteInfo: null,
      userAuth: undefined
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const { invite_code } = getUrlParams();
      if (invite_code) {
        this.tokenAuth();
        const params = JSON.parse(window.atob(invite_code));
        const { data } = await this.$store.dispatch('queryInviteInfo', {
          inviteUserId: params.inviteUserId, // 邀请发起人
          teamId: params.teamId, // 团队id
        });
        this.inviteParams = params;
        this.inviteInfo = data;
      }
    },
    async tokenAuth() {
      const token = Storage.get(ENV.storage.token);
      if (token && !this.$store.state.auth) {
        const res = await this.$store.dispatch('tokenAuth', token);
        this.userAuth = Boolean(res);
      } else {
        this.userAuth = false;
      }
    },
    changeEmail(e) {
      this.email = e.target.value;
    },
    async sendEmailCode() {
      if (!this.email) {
        this.form.setFields({
          email: {
            value: '',
            errors: [new Error('请输入邮箱')],
          },
        });
        return;
      }
      const res = await this.$store.dispatch('sendEmailCode', {
        email: this.email,
        type: 'register',
      });
      if (res.code === 0) {
        this.interval(); //执行倒计时
        this.$message.success(
          `已将验证码发送到您${this.email}的邮箱当中，请注意查收！`
        );
      } else {
        this.$message.error('抱歉，邮件发送失败！');
      }
    },
    //短信倒计时
    interval() {
      let num = 60;
      this.btnText = '重新发送(' + num + 's)';
      this.btnStyle = 'disabled';
      this.timer = setInterval(() => {
        if (num === 1) {
          this.ajaxFlag = true;
          this.btnText = '重新获取';
          (this.btnStyle = this.email ? 'actived' : null), (this.num = 60);
          clearInterval(this.timer);
        } else {
          num--;
          this.btnText = '重新发送(' + num + 's)';
          this.num = num;
        }
      }, 1000);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.inviteParams) {
            values.inviteUserId = this.inviteParams.inviteUserId;
            values.teamId = this.inviteParams.teamId;
            values.memberRole = this.inviteParams.memberRole;
            values.requiredApproval = this.inviteParams.requiredApproval;
          }
          values.password = Encrypt(values.email, values.password);
          this.$store.dispatch('register', values);
        }
      });
    },
    // 申请加入团队，requiredApproval决定是否需管理员要审核
    joinTeam() {
      this.$store.dispatch('joinTeam', this.inviteParams);
    },
  },
};
</script>

<style lang="scss">
.login {
  .box-card {
    width: 400px;
    margin: auto;
  }
  h1 {
    font-size: 18px;
    font-weight: 400;
  }
  button {
    width: 100%;
  }
  .desc {
    margin-top: 20px;
  }
  .ant-form-explain {
    text-align: left;
  }
  .emailcode-btn {
    color: var(--content-color-tertiary);
    &:hover {
      color: var(--primary-color);
    }
  }
  .ant-select-disabled .ant-select-arrow-icon {
    display: none;
  }
}
</style>
