var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('a-card',{staticClass:"box-card"},[(_vm.inviteInfo)?_c('h1',[_c('span',[_vm._v("用户 "+_vm._s(_vm.inviteInfo.user ? this.inviteInfo.user.username : null))]),_c('br'),_c('span',[_vm._v("邀请你加入「"+_vm._s(_vm.inviteInfo.team ? this.inviteInfo.team.name : null)+"」")])]):_vm._e(),(this.userAuth === true)?_c('a-button',{staticClass:"margin-top",attrs:{"type":"primary","size":"large"},on:{"click":_vm.joinTeam}},[_vm._v("加入团队")]):_vm._e(),(this.userAuth === false)?_c('a-form',{staticClass:"login-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userRole',
            {
              initialValue: _vm.inviteParams
                ? _vm.inviteParams.memberRole
                : undefined,
            } ]),expression:"[\n            'userRole',\n            {\n              initialValue: inviteParams\n                ? inviteParams.memberRole\n                : undefined,\n            },\n          ]"}],attrs:{"disabled":true}},_vm._l((_vm.teamMemberRoleOptions),function(role){return _c('a-select-option',{key:role.value,attrs:{"disabled":role.value === 'owner'}},[_vm._v(" 用户角色: "+_vm._s(role.label)+" ")])}),1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  required: true,
                  message: '请输入邮箱地址',
                },
                {
                  type: 'email',
                  message: '邮箱格式有误',
                } ],
            } ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入邮箱地址',\n                },\n                {\n                  type: 'email',\n                  message: '邮箱格式有误',\n                },\n              ],\n            },\n          ]"}],attrs:{"allowClear":"","placeholder":"邮箱地址"},on:{"change":_vm.changeEmail}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'emailcode',
            {
              rules: [
                {
                  required: true,
                  message: '请输入验证码',
                },
                {
                  len: 6,
                  message: '验证码格式有误',
                } ],
            } ]),expression:"[\n            'emailcode',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入验证码',\n                },\n                {\n                  len: 6,\n                  message: '验证码格式有误',\n                },\n              ],\n            },\n          ]"}],attrs:{"allowClear":"","placeholder":"邮箱验证码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"code"},slot:"prefix"}),_c('a-button',{staticClass:"emailcode-btn",attrs:{"slot":"suffix","type":"link","disabled":_vm.btnStyle !== 'actived'},on:{"click":_vm.sendEmailCode},slot:"suffix"},[_vm._v(_vm._s(_vm.btnText))])],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'username',
            {
              rules: [
                {
                  required: true,
                  message: '请输入用户名',
                } ],
            } ]),expression:"[\n            'username',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入用户名',\n                },\n              ],\n            },\n          ]"}],attrs:{"allowClear":"","placeholder":"用户名"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: '请输入密码',
                } ],
            } ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入密码',\n                },\n              ],\n            },\n          ]"}],attrs:{"allowClear":"","placeholder":"密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticStyle:{"margin":"0"}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","size":"large"},on:{"click":_vm.handleSubmit}},[_vm._v(" 注册 ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }